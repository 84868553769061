import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CSSProperties, Dispatch, SetStateAction, useRef, useState } from "react";
import {
  ManufacturingGroup,
  ManufacturingPriority,
  ManufacturingUnit,
} from "api/manufacturing/units/models";
import { Pagination } from "api/types";
import AutoSizer from "react-virtualized-auto-sizer";

import { ColumnWrapper } from "../ColumnWrapper";
import {
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import { EmptyTodoSection } from "./EmptyTodoSection";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useQuery, useSelector } from "hooks";
import { ColumnSearch } from "../../../subcomponents/ColumnSearch";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { ManufacturingTicket } from "../../shared/manufacturingTicket/ManufacturingTicket";
import { manufacturingActions } from "api/manufacturing/actions";
import { queryString } from "utilities";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { ManufacturingTicketProps } from "../../shared/manufacturingTicket/types";
import { manufacturingItemsConstants } from "constants/manufacturingItemsConstants";
import { DrawerRenderer, useManufacturingBoardDrawer } from "../../panel/DrawerRenderer";
import { ColumnType } from "../../ColumnView";
import { PaginatedQueryReturnType } from "hooks/createPaginatedQuery";
import { ApiQueryReturnType } from "hooks/createApiQuery";
import { getDraggableElementStyles } from "../shared";
import { VariableSizeList as List } from "react-window";
import { OrderTypeChoices } from "api/orders/enums";

export const DEFAULT_NUMBER_OF_UNITS_DISPLAYED = 4 as const;

export const TodoSection = () => {
  const stageId = useStageId();
  const { query } = useQuery();
  const [showMoreUnits, setShowMoreUnits] = useState(false);
  const search = manufacturingStagesUtils.getTodoUnitColumnSearch(
    { ...query, pageSize: showMoreUnits ? "999" : String(DEFAULT_NUMBER_OF_UNITS_DISPLAYED) },
    stageId,
  );
  const unitItemQuery = manufacturingUnitsActions.useGetUnitItems(search, {
    keepPreviousData: true,
  });

  const groupsSearch = useGroupSearch();
  const groupQuery = manufacturingUnitsActions.useGetManufacturingGroups(
    {
      id: stageId,
      search: groupsSearch.search,
    },
    {
      enabled: !groupsSearch.isLoading,
    },
  );

  if (
    (groupQuery.isLoading && !groupQuery.isPreviousData) ||
    (unitItemQuery.isLoading && !unitItemQuery.isPreviousData)
  )
    return (
      <div className="d-flex position-relative flex-1 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center overflow-hidden h-100">
            <Spinner size={48} />
          </div>
        </ColumnWrapper>
      </div>
    );

  if (groupQuery.error || unitItemQuery.error)
    return (
      <div className="d-flex position-relative flex-1 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <CommonError
              status={groupQuery.error?._httpStatus_ || unitItemQuery.error?._httpStatus_}
            />
          </div>
        </ColumnWrapper>
      </div>
    );

  if (!unitItemQuery.data?.length && !groupQuery.data?.length) {
    return (
      <div className="d-flex flex-column flex-1 px-0">
        <EmptyTodoSection groupQuery={groupQuery} unitItemQuery={unitItemQuery} />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column overflow-hidden flex-1 px-0">
      <ColumnWrapper>
        <PrioritiesSection
          setShowMoreUnits={setShowMoreUnits}
          showMoreUnits={showMoreUnits}
          unitItemQuery={unitItemQuery}
        />
        <GroupSection groupQuery={groupQuery} />
      </ColumnWrapper>

      <DrawerRenderer columnType={ColumnType.TODO} />
    </div>
  );
};

const PrioritiesSection = ({
  unitItemQuery,
  setShowMoreUnits,
  showMoreUnits,
}: {
  unitItemQuery: PaginatedQueryReturnType<Pagination<ManufacturingUnit>>;
  showMoreUnits: boolean;
  setShowMoreUnits: Dispatch<SetStateAction<boolean>>;
}) => {
  const { toggleDrawer } = useManufacturingBoardDrawer();
  const { query, updateQuery } = useQuery();
  const { data: units, isFetching, pagination } = unitItemQuery;
  return (
    <Droppable droppableId="todoSingleSectionDroppable">
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <div className="d-flex align-items-center justify-content-between py-2 gap-3">
            <div className="d-flex align-items-center gap-2">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
                Priorytety
              </Typography>
              <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                {pagination.count || "brak"}
              </Typography>
              {isFetching && <Spinner size={16} />}
            </div>
            <ColumnSearch
              isLoading={isFetching}
              queryKey="todoUnitsSearch"
              onChange={search => updateQuery({ ...query, todoUnitsSearch: search ?? "" })}
              value={query["todoUnitsSearch"]}
            />
          </div>

          <div>
            <div className="d-flex flex-column">
              {(showMoreUnits ? units : units.slice(0, DEFAULT_NUMBER_OF_UNITS_DISPLAYED)).map(
                (unit, index) => (
                  <Draggable
                    draggableId={`${manufacturingItemsConstants.DRAGGABLE_BOARD_ITEMS.TODO_MWU};${unit.id}`}
                    key={`unit-${unit.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDraggableElementStyles(snapshot, provided.draggableProps.style)}
                      >
                        <ManufacturingTicket
                          canAssignEmployee
                          isDragging={snapshot.isDragging}
                          onClick={ticket => {
                            toggleDrawer({
                              columnType: ColumnType.TODO,
                              id: unit.id,
                              type: "unitItem",
                            });
                          }}
                          ticket={{
                            productName: unit.name,
                            attributesValues: unit.attributeValues,
                            employee: unit.employee,
                            id: unit.id,
                            cancelledElements: unit.isCancelled ? 1 : 0,
                            declinedElements: unit.isDeclined ? 1 : 0,
                            isComplaint: unit.orderType === OrderTypeChoices.COMPLAINT,
                            implementedBy: unit.implementedBy,
                            manufacturer: unit.manufacturer?.name,
                            manufacturingItems: [unit.manufacturingItem.id],
                            note: unit.note,
                            signature: unit.manufacturingItem?.signature,
                            priority: unit.priority,
                            externalOrderNumber: unit.manufacturingItem.externalOrderNumber,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ),
              )}
              <span
                style={{
                  display: "none",
                }}
              >
                {provided.placeholder}
              </span>
            </div>
            {Boolean(pagination?.count && pagination.count > DEFAULT_NUMBER_OF_UNITS_DISPLAYED) && (
              <div className="pt-2">
                {showMoreUnits ? (
                  <Button
                    className="text-uppercase"
                    onClick={() => setShowMoreUnits(false)}
                    size="small"
                    variant="gray"
                  >
                    Pokaż mniej
                  </Button>
                ) : (
                  <Button
                    className="text-uppercase"
                    onClick={() => setShowMoreUnits(true)}
                    size="small"
                    variant="gray"
                  >
                    Pokaż pozostałe {pagination!.count! - DEFAULT_NUMBER_OF_UNITS_DISPLAYED}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};

const GroupSection = ({ groupQuery }: { groupQuery: ApiQueryReturnType<ManufacturingGroup[]> }) => {
  const { query, updateQuery } = useQuery();
  const stageId = useStageId();
  const listRef = useRef(null);

  const { data: manufacturingItemCount } = manufacturingUnitsActions.useStatistics({
    stageId,
    search: "",
  });
  const { data: groups, isLoading, isFetching } = groupQuery;

  if (isLoading)
    return (
      <div className="position-relative px-0">
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spinner size={48} />
        </div>
      </div>
    );

  if (!groups?.length) return null;

  return (
    <Droppable
      droppableId="todoGroupSectionDroppable"
      mode="virtual"
      renderClone={(
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        rubric: DraggableRubric,
      ) => (
        <Item
          snapshot={snapshot}
          provided={provided}
          group={groups?.[rubric.source.index]!}
          index={rubric.source.index}
        />
      )}
    >
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="d-flex flex-column gap-1 flex-1"
        >
          <div className="d-flex align-items-center justify-content-between py-2 gap-3">
            <div className="d-flex align-items-center gap-2">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
                Do zrobienia
              </Typography>
              <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                {manufacturingItemCount?.counts.notStarted ?? 0}
              </Typography>
              {isFetching && <Spinner size={16} />}
            </div>
            <ColumnSearch
              isLoading={isFetching}
              queryKey="todoGroupsSearch"
              onChange={search => updateQuery({ ...query, todoGroupsSearch: search ?? "" })}
              value={query["todoGroupsSearch"]}
            />
          </div>

          <AutoSizer className="d-flex flex-column flex-1">
            {({ height, width }) => (
              <List
                className="List"
                height={height - 60}
                itemCount={groups?.length || 30}
                itemSize={() => 54}
                ref={listRef}
                width={width}
                itemData={groups}
              >
                {Row}
              </List>
            )}
          </AutoSizer>

          <span
            style={{
              display: "none",
            }}
          >
            {provided.placeholder}
          </span>
        </div>
      )}
    </Droppable>
  );
};

const Item = ({
  group,
  snapshot,
  provided,
  index,
  style,
}: {
  snapshot: DraggableStateSnapshot;
  provided: DraggableProvided;
  style?: CSSProperties;
  group: ManufacturingGroup;
  index?: number;
}) => {
  const { toggleDrawer } = useManufacturingBoardDrawer();
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-index={index}
      style={{
        ...getDraggableElementStyles(snapshot, provided.draggableProps.style),
        ...style,
      }}
    >
      <ManufacturingTicket
        isDragging={snapshot.isDragging}
        onClick={ticket => {
          if (group.elements.length === 1) {
            toggleDrawer({
              columnType: ColumnType.TODO,
              id: String(group.elements[0].id),
              type: "unitItem",
            });
            return;
          }
          toggleDrawer({
            columnType: ColumnType.TODO,
            id: String(group.id),
            type: "group",
          });
        }}
        ticket={getTicketBasedOnGroup(group)}
      />
    </div>
  );
};

const Row = ({
  index,
  style,
  data: groups,
}: {
  index: number;
  style: Object;
  data: ManufacturingGroup[] | null;
}) => {
  const group = groups?.[index]!;
  return (
    <Draggable
      draggableId={`${manufacturingItemsConstants.DRAGGABLE_BOARD_ITEMS.TODO_GROUP};${group?.id}`}
      key={`group-${group?.id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <Item provided={provided} group={group} snapshot={snapshot} style={style} />
      )}
    </Draggable>
  );
};
export interface GroupFilters {
  attributesKinds: string;
  groupByModel: string;
  search: string;
}

export const useGroupSearch = () => {
  const stageId = useStageId();
  const { query } = useQuery();
  const manufacturingStages = useSelector(store => store.partials.stages);
  const manufacturingStage = manufacturingStages.find(stage => stage.id === stageId);
  const {
    data: defaultFilters,
    isLoading,
  } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  return {
    search: manufacturingStagesUtils.getTodoGroupColumnSearch(
      query,
      manufacturingStage!.boardFormat,
      attributesKinds,
    ),
    isLoading,
  };
};

const getTicketBasedOnGroup = (group: ManufacturingGroup): ManufacturingTicketProps["ticket"] => {
  if (group?.elements.length === 1) {
    const unit = group.elements?.[0];
    return {
      id: unit.id,
      attributesValues: group.attributesValues,
      isComplaint: unit.orderType === OrderTypeChoices.COMPLAINT,
      productName: unit.name,
      cancelledElements: unit.isCancelled ? 1 : 0,
      declinedElements: unit.isDeclined ? 1 : 0,
      // implementedBy: group.implementedBy,
      // manufacturer: group.manufacturer?.name,
      manufacturingItems: [unit.manufacturingItemId],
      note: unit.note,
      signature: unit.signature,
      priority: unit.priority,
      externalOrderNumber: unit.externalOrderNumber,
    };
  }

  return {
    productName: group.modelName,
    attributesValues: group.attributesValues,
    cancelledElements: group.elements.filter(element => element.isCancelled).length,
    declinedElements: group.elements.filter(element => element.isDeclined).length,
    isComplaint: group.elements.some(element => element.orderType === OrderTypeChoices.COMPLAINT),
    elementsCount: {
      A: group.elements.filter(element => element.priority === ManufacturingPriority.A).length,
      B: group.elements.filter(element => element.priority === ManufacturingPriority.B).length,
      C: group.elements.filter(element => element.priority === ManufacturingPriority.C).length,
      D: 0,
      E: 0,
    },
    id: String(group.id),
    manufacturingItems: group.elements.map(unit => unit.manufacturingItemId),
    externalOrderNumber: "",
  };
};
