import { MaterialStageStatus } from "api/manufacturing/units/models";
import { InfoLabel } from "components/common/infoLabel";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { dateFns } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Link } from "components/miloDesignSystem/atoms/link";
import styles from "../../../listView/ListView.module.css";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { ManufacturingItem } from "api/manufacturing/models";
import { getScheduledAtLabel } from "../../../listView/useUnitColumns";

interface Props {
  manufacturingItem: ManufacturingItem;
}

export const GeneralInfoSection = ({ manufacturingItem }: Props) => {
  if (!manufacturingItem) return null;

  return (
    <div className="d-flex flex-column">
      <div className="py-2 px-3">
        <div className="pb-2">
          <Typography fontSize="20" fontWeight="700">
            {manufacturingItem.name || EMPTY_VALUE}
          </Typography>
        </div>
      </div>
      <RightPanelSection padding="px-3 pt-0">
        <div className="pb-2">
          <InfoLabel title="tkanina">
            {!manufacturingItem.materials.length && <EmptyValue fontSize="14" fontWeight="600" />}
            {manufacturingItem.materials.map(material => (
              <div
                className="d-flex align-items-center gap-2 justify-content-between"
                key={material.id}
              >
                <div className="d-flex align-items-center gap-1 flex-1 cut-text">
                  <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
                    {material.name}
                  </Typography>
                </div>
                {manufacturingStagesUtils.areSomeMaterialsNotOrdered([material]) ? (
                  <Tag
                    label={
                      manufacturingStagesConstants.materialsStatusOptions[
                        MaterialStageStatus.NOT_ORDERED
                      ].label
                    }
                    variant={
                      manufacturingStagesConstants.materialsStatusOptions[
                        MaterialStageStatus.NOT_ORDERED
                      ].variant
                    }
                  />
                ) : manufacturingStagesUtils.areSomeMaterialsOrdered([material]) ? (
                  <Tag
                    label={
                      manufacturingStagesConstants.materialsStatusOptions[
                        MaterialStageStatus.ORDERED
                      ].label
                    }
                    variant={
                      manufacturingStagesConstants.materialsStatusOptions[
                        MaterialStageStatus.ORDERED
                      ].variant
                    }
                  />
                ) : (
                  <Tag
                    label={
                      manufacturingStagesConstants.materialsStatusOptions[
                        MaterialStageStatus.RECEIVED
                      ].label
                    }
                    variant={
                      manufacturingStagesConstants.materialsStatusOptions[
                        MaterialStageStatus.RECEIVED
                      ].variant
                    }
                  />
                )}
              </div>
            ))}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="wariant">
            {manufacturingItem.index ? (
              <Link
                fontSize="14"
                fontWeight="500"
                to={`/manufacturing/indexes/list/upholstery?panelId=${manufacturingItem.index.id}`}
              >
                {manufacturingItem.index.name}
              </Link>
            ) : (
              <EmptyValue fontSize="14" fontWeight="500" />
            )}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="przygotować do">
            {manufacturingItem.scheduledAt ? (
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="14" fontWeight="600">
                  {dateFns.format(new Date(manufacturingItem.scheduledAt), "d.MM")}
                </Typography>
                {getScheduledAtLabel(manufacturingItem.scheduledAt) && (
                  <Typography
                    color={getScheduledAtLabel(manufacturingItem.scheduledAt)!.color}
                    fontSize="14"
                    fontWeight="600"
                  >
                    ({getScheduledAtLabel(manufacturingItem.scheduledAt)!.label})
                  </Typography>
                )}
              </div>
            ) : (
              <EmptyValue fontSize="14" fontWeight="700" />
            )}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="obecny etap">
            {!Boolean(manufacturingItem.currentStages.length) ? (
              <EmptyValue fontSize="16" fontWeight="700" />
            ) : (
              <div className="d-flex align-items-center gap-1">
                <Tag
                  label={manufacturingItem.currentStages[0].name}
                  type="outlined"
                  variant="warning"
                />
                <Tag
                  endIcon={
                    <div className={styles.previousStageIcon}>
                      {
                        manufacturingStagesConstants.stageStatusIconDict[
                          manufacturingItem.currentStages[0].status
                        ]
                      }
                    </div>
                  }
                  label={
                    manufacturingStagesConstants.stageStatusOptions[
                      manufacturingItem.currentStages[0].status
                    ].label
                  }
                  type={
                    manufacturingStagesConstants.stageStatusOptions[
                      manufacturingItem.currentStages[0].status
                    ].type
                  }
                  variant={
                    manufacturingStagesConstants.stageStatusOptions[
                      manufacturingItem.currentStages[0].status
                    ].variant
                  }
                />
                {manufacturingItem.currentStages.length >= 2 && (
                  <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                    +{manufacturingItem.currentStages.length - 1}
                  </Typography>
                )}
              </div>
            )}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="w etapie od">
            <Typography fontSize="14" fontWeight="400">
              {manufacturingItem.startedAt
                ? dateFns.formatRelative(new Date(manufacturingItem.startedAt), "dd.MM.yyyy, H:mm")
                : EMPTY_VALUE}
            </Typography>
          </InfoLabel>
        </div>

        <div className="pb-2">
          <InfoLabel title="lokalizacja">
            <Typography fontSize="14" fontWeight="400">
              {manufacturingItem.locations?.join(", ") || EMPTY_VALUE}
            </Typography>
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="zamówienie">
            {manufacturingItem.order ? (
              <Link
                fontSize="14"
                fontWeight="500"
                to={`/orders/list/active/all?panelId=${manufacturingItem.order.id}`}
              >
                {manufacturingItem.order.signature}
              </Link>
            ) : (
              <EmptyValue fontSize="14" fontWeight="500" />
            )}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="trasa/grupa">
            {manufacturingItem.route && (
              <Link
                fontSize="14"
                fontWeight="600"
                to={`/routes/list/active?panelId=${manufacturingItem.route.id}`}
              >
                {manufacturingItem.route.signature}
              </Link>
            )}
            {manufacturingItem.orderGroup && (
              <Link
                fontSize="14"
                fontWeight="600"
                to={`/order-groups/list/orders?panelId=${manufacturingItem.orderGroup.id}`}
              >
                {manufacturingItem.orderGroup.signature}
              </Link>
            )}
          </InfoLabel>
        </div>
      </RightPanelSection>
    </div>
  );
};
