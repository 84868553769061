import {
  ImplementedBy,
  ManufacturingUnitListViewItem,
  MaterialStageStatus,
} from "api/manufacturing/units/models";
import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../StageBoard.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { dateFns, getAnyErrorKey, queryString } from "utilities";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { CountryCode, channels } from "CONSTANTS";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { ISODateTime } from "api/types";
import { startOfDay } from "date-fns";
import { orderActions } from "api/orders/actions";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Link } from "components/miloDesignSystem/atoms/link";
import { ErrorMessage } from "components/utils";
import { CreatePickingOrLoadingSource } from "api/orders/models";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { HistoryPayload } from "./ListView";
import { MdiClaim } from "components/miloDesignSystem/atoms/icons/MdiClaim";
import { OrderTypeChoices } from "api/orders/enums";

export const useUnitColumns = (
  units: ManufacturingUnitListViewItem[],
  historyModal: {
    isOpen: boolean;
    open: (stateToSet: HistoryPayload) => void;
    close: () => void;
    state: HistoryPayload | null;
  },
) => {
  const { data: ordersCollectionAssignments, error, isLoading } = orderActions.useOrdersGroupByInfo(
    queryString.stringify({
      ids: units.map(unit => unit.manufacturingItem.orderId).join(","),
    }),
    {
      enabled: Boolean(units.length),
    },
  );
  const {
    data: ordersSalesAccountInfo,
    error: salesAccountInfoError,
    isLoading: isLoadingSalesAccountInfo,
  } = orderActions.useOrdersSalesAccountInfo(
    queryString.stringify({
      ids: units.map(unit => unit.manufacturingItem.orderId).join(","),
    }),
    {
      enabled: Boolean(units.length),
    },
  );

  return useCreateTableColumns<ManufacturingUnitListViewItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "zlecenie",
        size: 190,
        cell: info => {
          const unit = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              {manufacturingStagesUtils.isUnitUrgent(unit.manufacturingItem.priority) && (
                <div
                  className={styles.marker}
                  style={{
                    background:
                      colorPalette[
                        manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                          unit.manufacturingItem.priority
                        ].light.color
                      ],
                  }}
                />
              )}
              {manufacturingStagesUtils.isUnitUrgent(unit.manufacturingItem.priority) && (
                <div
                  className={styles.ticketIcon}
                  style={{
                    borderColor:
                      colorPalette[
                        manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                          unit.manufacturingItem.priority
                        ].light.color
                      ],
                  }}
                >
                  {
                    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                      unit.manufacturingItem.priority
                    ].light.icon
                  }
                </div>
              )}
              <Typography
                className={styles.ticketSignature}
                color={
                  manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                    unit.manufacturingItem.priority
                  ].light.color
                }
                fontSize="14"
                fontWeight="600"
                noWrap
              >
                {unit.manufacturingItem.signature}
                {unit.orderType === OrderTypeChoices.COMPLAINT && (
                  <MdiClaim color="magenta200" size="16" />
                )}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.manufacturingItem.externalOrderNumber, {
        header: "nr. zlec. klienta",
        size: 130,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.manufacturingItem.name, {
        header: "model",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row.previousStages, {
        header: "poprzednie etapy",
        size: 100,
        cell: info => {
          const previousStages = info.getValue();
          if (!Boolean(previousStages.length)) return <EmptyValue fontSize="14" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1 cut-text">
              <Tag
                endIcon={
                  <div className={styles.previousStageIcon}>
                    {manufacturingStagesConstants.stageStatusIconDict[previousStages[0].status]}
                  </div>
                }
                label={previousStages[0].name}
                type="outlined"
                variant="warning"
              />
              {previousStages.length >= 2 && (
                <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                  +{previousStages.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.materials, {
        header: "status zam. materiału",
        size: 120,
        cell: info => {
          const materials = info.getValue();
          const customOrderedMaterials = materials.filter(material => material.isCustomOrdered);
          if (!Boolean(customOrderedMaterials.length))
            return <EmptyValue fontSize="14" fontWeight="700" />;

          if (manufacturingStagesUtils.areSomeMaterialsNotOrdered(customOrderedMaterials))
            return (
              <Tag
                label={
                  manufacturingStagesConstants.materialsStatusOptions[
                    MaterialStageStatus.NOT_ORDERED
                  ].label
                }
                variant={
                  manufacturingStagesConstants.materialsStatusOptions[
                    MaterialStageStatus.NOT_ORDERED
                  ].variant
                }
              />
            );

          if (manufacturingStagesUtils.areSomeMaterialsOrdered(customOrderedMaterials))
            return (
              <Tag
                label={
                  manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.ORDERED]
                    .label
                }
                variant={
                  manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.ORDERED]
                    .variant
                }
              />
            );

          return (
            <Tag
              label={
                manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.RECEIVED]
                  .label
              }
              variant={
                manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.RECEIVED]
                  .variant
              }
            />
          );
        },
      }),
      columnHelper.accessor(row => row.materials, {
        header: "materiał",
        size: 140,
        cell: info => {
          const materials = info.getValue();
          if (!Boolean(materials.length)) return <EmptyValue fontSize="14" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1 cut-text">
              <Typography fontSize="14" fontWeight="600" noWrap>
                {materials.map(material => material.name).join(", ")}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.nextStages, {
        header: "następny etap",
        size: 105,
        cell: info => {
          const nextStages = info.getValue();
          if (!Boolean(nextStages.length)) return <EmptyValue fontSize="14" />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Tag
                endIcon={
                  <div className={styles.previousStageIcon}>
                    {manufacturingStagesConstants.stageStatusIconDict[nextStages[0].status]}
                  </div>
                }
                label={nextStages[0].name}
                type="outlined"
                variant="warning"
              />
              {nextStages.length >= 2 && (
                <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                  +{nextStages.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.employee, {
        header: "przyp.",
        size: 40,
        cell: info => {
          const employee = info.getValue();
          if (!employee) return <EmptyValue fontSize="14" fontWeight="700" />;
          return <Avatar size="sm" theme="light" user={employee} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "realizacja",
        size: 120,
        cell: info => {
          const unit = info.getValue();
          if (unit.implementedBy === ImplementedBy.CONTRACTOR && unit.manufacturer)
            return <Tag label={unit.manufacturer.name} variant="warning" />;
          return (
            <Tag
              label={manufacturingStagesConstants.implementedByOptions[unit.implementedBy].label}
              variant={
                manufacturingStagesConstants.implementedByOptions[unit.implementedBy].variant
              }
            />
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 92,
        cell: info => {
          const status = info.getValue();
          return (
            <Tag
              label={manufacturingStagesConstants.unitStatusOptions[status].label}
              type={manufacturingStagesConstants.unitStatusOptions[status].type}
              variant={manufacturingStagesConstants.unitStatusOptions[status].variant}
            />
          );
        },
      }),
      columnHelper.text(
        row => {
          if (!row.startedAt) return null;
          return dateFns.formatRelative(new Date(row.startedAt), "dd.MM.yyyy, H:mm");
        },
        {
          header: "w etapie od",
          size: 110,
        },
      ),
      columnHelper.accessor(row => row.scheduledAt, {
        header: "wyprodukować do",
        size: 120,
        cell: info => {
          const scheduledAt = info.getValue();
          if (!scheduledAt) return <EmptyValue fontSize="14" fontWeight="700" />;

          const scheduledAtLabel = getScheduledAtLabel(scheduledAt);

          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="14" fontWeight="600">
                {dateFns.format(new Date(scheduledAt), "d.MM")}
              </Typography>
              {scheduledAtLabel && (
                <Typography color={scheduledAtLabel.color} fontSize="14" fontWeight="600">
                  ({scheduledAtLabel.label})
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.location || null, {
        header: "lokalizacja",
        size: 110,
      }),
      columnHelper.accessor(row => row, {
        header: "trasa/grupa",
        size: 110,
        cell: info => {
          const unit = info.getValue();
          const searchedUnit = ordersCollectionAssignments?.find(
            assignment => assignment.id === unit.manufacturingItem.orderId,
          );

          if (isLoading) return <LoaderCell height={mainListBigUiSchema.cell.height} width={50} />;

          if (error)
            return (
              <Typography className="pb-1" color="danger600" fontSize="16" fontWeight="600" noWrap>
                <ErrorMessage text={getAnyErrorKey(error)} type="text" />
              </Typography>
            );

          if (!searchedUnit || !searchedUnit.group)
            return <EmptyValue fontSize="14" fontWeight="700" />;

          return (
            <Link
              fontSize="14"
              fontWeight="600"
              to={
                searchedUnit.group.type === CreatePickingOrLoadingSource.ROUTE
                  ? `/routes/list/active?panelId=${searchedUnit.group.id}`
                  : `/order-groups/list/orders?panelId=${searchedUnit.group.id}`
              }
            >
              {searchedUnit.group.signature}
            </Link>
          );
        },
      }),
      columnHelper.accessor(row => row.manufacturingItem, {
        header: "kraj wysyłki",
        size: 70,
        cell: info => {
          const item = info.getValue();
          if (!item || !item.countryCode) return <EmptyValue fontSize="14" fontWeight="700" />;
          return <CountryFlag countryCode={item.countryCode as CountryCode} />;
        },
      }),
      columnHelper.text(row => row.orderSignature, {
        header: "zamówienie",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "konto sprzedażowe",
        size: 115,
        cell: info => {
          const unit = info.getValue();
          const searchedUnit = ordersSalesAccountInfo?.find(
            order => order.id === unit.manufacturingItem.orderId,
          );

          if (isLoadingSalesAccountInfo)
            return <LoaderCell height={mainListBigUiSchema.cell.height} width={50} />;

          if (salesAccountInfoError)
            return (
              <Typography className="pb-1" color="danger600" fontSize="16" fontWeight="600" noWrap>
                <ErrorMessage text={getAnyErrorKey(salesAccountInfoError)} type="text" />
              </Typography>
            );

          if (!searchedUnit || !searchedUnit.salesAccount)
            return <EmptyValue fontSize="14" fontWeight="700" />;

          return (
            <div>
              {channels[searchedUnit.salesAccount.channel]?.icon ? (
                <img alt="marketplace" src={channels[searchedUnit.salesAccount.channel]?.icon} />
              ) : (
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {searchedUnit.salesAccount.name}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "additionalActions",
        header: " ",
        size: 66,
        cell: info => {
          const unit = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <FileDownloadHandler
                factoryFn={() =>
                  manufacturingFileFactory.manufacturingItemPdf(
                    [unit.manufacturingItem.id],
                    unit.manufacturingItem.signature,
                  )
                }
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <Tooltip title="Pobierz etykietę">
                    <IconButton
                      icon={MdiQrCode}
                      isLoading={isLoading}
                      onClick={event => {
                        event.stopPropagation();
                        download();
                      }}
                      variant="transparent"
                    />
                  </Tooltip>
                )}
              </FileDownloadHandler>
              <Tooltip title="Historia zlecenia">
                <IconButton
                  icon={MdiHistory}
                  onClick={event => {
                    event.stopPropagation();
                    historyModal.open({
                      unitId: unit.id,
                      signature: unit.manufacturingItem.signature,
                    });
                  }}
                  variant="transparent"
                />
              </Tooltip>
            </div>
          );
        },
      }),
    ];
  });
};

export const getScheduledAtLabel = (
  scheduledAt: ISODateTime,
): { label: string; color: ColorPalette } | null => {
  const today = startOfDay(new Date());
  const normalizedDate = startOfDay(new Date(scheduledAt));
  const differenceInDays = dateFns.differenceInDays(new Date(normalizedDate), today);
  if (dateFns.isToday(new Date(normalizedDate))) return { color: "warning600", label: "dzisiaj" };
  if (dateFns.isYesterday(new Date(normalizedDate)))
    return { color: "danger600", label: "wczoraj" };
  if (dateFns.isTomorrow(new Date(normalizedDate))) return { color: "warning600", label: "jutro" };
  if (differenceInDays > 1 && differenceInDays <= 3)
    return { color: "warning600", label: `za ${differenceInDays} dni` };
  if (differenceInDays > 3 && differenceInDays <= 7)
    return { color: "neutralBlack100", label: `za ${differenceInDays} dni` };
  if (differenceInDays >= -7 && differenceInDays < -1)
    return { color: "danger600", label: `${Math.abs(differenceInDays)} dni temu` };

  return null;
};
