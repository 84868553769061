import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import styles from "./ManufacturingTicket.module.css";
import { ManufacturingTicketProps } from "./types";
import { cx } from "utilities";
import { useStateModal } from "hooks";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { MdiStacks } from "components/miloDesignSystem/atoms/icons/MdiStacks";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import {
  DeclinedNoteState,
  ImplementedBy,
  ManufacturingPriority,
} from "api/manufacturing/units/models";
import { isAfter, isBefore, subMinutes } from "date-fns";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiQualityAlert } from "components/miloDesignSystem/atoms/icons/MdiQualityAlert";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { ClickOutsideHandler } from "components/utils";
import { MdiCancelBg } from "components/miloDesignSystem/atoms/icons/MdiCancelBg";
import { MdiStickyNote2 } from "components/miloDesignSystem/atoms/icons/MdiStickyNote2";
import { DeclinedNote } from "../../../declinedNote/DeclinedNote";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { AssignEmployee } from "./components/AssignEmployee";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { useManufacturingBoardDrawer } from "../../panel/DrawerRenderer";
import { MdiClaim } from "components/miloDesignSystem/atoms/icons/MdiClaim";

export const ManufacturingTicket = ({
  canAssignEmployee,
  isDragging,
  onClick,
  ticket,
}: ManufacturingTicketProps) => {
  const { currentDrawer } = useManufacturingBoardDrawer();
  const isPrioritized = ticket.priority && manufacturingStagesUtils.isUnitUrgent(ticket.priority);
  const isCritical = ticket.priority && manufacturingStagesUtils.isUnitCritical(ticket.priority);
  const groupElementCount = Object.values(ticket.elementsCount || {}).reduce(
    (sum, value) => sum + value,
    0,
  );
  const isRowCancelled =
    (ticket.cancelledElements || 0) >= 1 && (groupElementCount || 0) === ticket.cancelledElements;
  const isRecentlyMoved = (() => {
    if (!ticket.recentlyMovedDate) return false;
    const now = new Date();
    const oneMinuteAgo = subMinutes(now, 1);

    return (
      isAfter(new Date(ticket.recentlyMovedDate), oneMinuteAgo) &&
      isBefore(new Date(ticket.recentlyMovedDate), now)
    );
  })();
  const declinedNoteModal = useStateModal<DeclinedNoteState>();

  return (
    <>
      <StyledManufacturingTicket
        isComplaint={ticket.isComplaint}
        className={cx("container col-12", {
          dashedTableRow: isRowCancelled,
        })}
        isActive={currentDrawer?.id === ticket.id}
        isCritical={isCritical}
        isDragged={isDragging}
        isRecentlyMoved={isRecentlyMoved}
        onClick={() => onClick(ticket)}
      >
        <div className={cx(styles.ticketHeader, "cut-text")}>
          <div className="d-flex align-items-center gap-2">
            {isPrioritized && (
              <div className="d-flex align-items-center gap-2">
                <TicketMarker priority={ticket.priority!} />
                <TicketIcon priority={ticket.priority!}>
                  {ticket.priority
                    ? manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                        ticket.priority
                      ].light.icon
                    : null}
                </TicketIcon>
              </div>
            )}
            {ticket.signature && (
              <Typography
                className="flex-shrink-1"
                color={
                  ticket.priority
                    ? manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                        ticket.priority
                      ].light.color
                    : "neutralBlack100"
                }
                fontSize="14"
                fontWeight="600"
                noWrap
              >
                {ticket.signature}
              </Typography>
            )}
            {ticket.implementedBy === ImplementedBy.CONTRACTOR && ticket.manufacturer && (
              <Tag label={ticket.manufacturer} variant="success" />
            )}
            {ticket.elementsCount !== undefined && (
              <div className="d-flex flex-shrink-0 align-items-center gap-1">
                <MdiStacks size="14" />
                <Typography fontSize="14" fontWeight="600">
                  {groupElementCount}
                </Typography>
              </div>
            )}
            {ticket.elementsCount !== undefined && Boolean(ticket.elementsCount.A) && (
              <div className="d-flex flex-shrink-0 align-items-center gap-1">
                <TicketIcon priority={ManufacturingPriority.A}>
                  {
                    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                      ManufacturingPriority.A
                    ].light.icon
                  }
                </TicketIcon>
                <Typography fontSize="14" fontWeight="600">
                  x{ticket.elementsCount.A}
                </Typography>
              </div>
            )}
            {ticket.elementsCount !== undefined && Boolean(ticket.elementsCount.B) && (
              <div className="d-flex flex-shrink-0 align-items-center gap-1">
                <TicketIcon priority={ManufacturingPriority.B}>
                  {
                    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                      ManufacturingPriority.B
                    ].light.icon
                  }
                </TicketIcon>
                <Typography fontSize="14" fontWeight="600">
                  x{ticket.elementsCount.B}
                </Typography>
              </div>
            )}
            {ticket.declinedElements && ticket.declinedElements !== 0 ? (
              <div className="d-flex flex-shrink-0 align-items-center gap-1">
                <TicketIcon declinedElements={ticket.declinedElements}>
                  <MdiQualityAlert color="yellow400" size="16" />
                </TicketIcon>
                <Typography fontSize="14" fontWeight="600">
                  x{ticket.declinedElements}
                </Typography>
              </div>
            ) : null}
            {ticket.cancelledElements && ticket.cancelledElements !== 0 ? (
              <div className="d-flex flex-shrink-0 align-items-center gap-1">
                <TicketIcon cancelledElements={ticket.cancelledElements}>
                  <MdiCancelBg color="deepOrange200" size="16" />
                </TicketIcon>
                <Typography fontSize="14" fontWeight="600">
                  x{ticket.cancelledElements}
                </Typography>
              </div>
            ) : null}
            <Typography color="neutralBlack48" fontSize="14" fontWeight="700" noWrap>
              {ticket.externalOrderNumber || ticket.groupSignature}
            </Typography>
          </div>
          <div className="d-flex flex-1 justify-content-end align-items-center gap-2">
            {isRowCancelled && <Tag label="rezygnacja" variant="warning" />}
            {Boolean(ticket.declinedElements) && (
              <Tag
                label="do poprawy"
                startIcon={<MdiQualityAlert color="warning600" size="14" />}
                variant="warning"
              />
            )}
            {!ticket.declinedElements && Boolean(ticket.note) && (
              <Tooltip title="Pokaż notatkę">
                <IconButton
                  icon={MdiStickyNote2}
                  onClick={event => {
                    event.stopPropagation();
                    declinedNoteModal.open({
                      note: ticket.note!,
                      signature: ticket.signature!,
                    });
                  }}
                  variant="transparent"
                />
              </Tooltip>
            )}
            {ticket.implementedBy !== ImplementedBy.CONTRACTOR &&
              ticket.elementsCount === undefined &&
              canAssignEmployee && (
                <Popover
                  aria-label={ticket.id}
                  content={({ hide }) => (
                    <ClickOutsideHandler
                      onClickOutside={hide}
                      outsideClickIgnoreClass={ignoreClickOutsideClassName}
                    >
                      <div onClick={event => event.stopPropagation()}>
                        <AssignEmployee ticket={ticket} />
                      </div>
                    </ClickOutsideHandler>
                  )}
                  hideOnClickOutside={false}
                  variant="DARK"
                >
                  <div
                    className="d-flex align-items-center gap-1"
                    onClick={event => event.stopPropagation()}
                  >
                    <Avatar size="sm" user={ticket.employee || null} theme="light" />
                  </div>
                </Popover>
              )}
            <FileDownloadHandler
              factoryFn={() =>
                manufacturingFileFactory.manufacturingItemPdf(
                  ticket.manufacturingItems,
                  ticket.signature || ticket.groupSignature || "etykiety",
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={event => {
                    event.stopPropagation();
                    download();
                  }}
                  variant="transparent"
                />
              )}
            </FileDownloadHandler>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className={cx(styles.ticketAttributes, "col-11 pl-0 pr-0 cut-text")}>
            <>
              {ticket.productName && (
                <>
                  {manufacturingStagesConstants.attributeCategoryIconsDict["PRODUCT"]}
                  <Typography fontSize="16" fontWeight="700">
                    {ticket.productName}
                  </Typography>
                </>
              )}
              {ticket.attributesValues
                .filter(attribute => attribute.value.id || attribute.value.name)
                .map(attribute => (
                  <div
                    className="d-flex align-items-center gap-1"
                    key={`todo-section-group-attribute-${attribute.attribute.id}`}
                  >
                    {manufacturingStagesConstants.attributeCategoryIconsDict[attribute.category]}
                    <Typography fontSize="14" fontWeight="600" noWrap>
                      {attribute.value.name}
                    </Typography>
                  </div>
                ))}
            </>
          </div>
          {ticket.isComplaint && <MdiClaim color="magenta200" size="16" />}
        </div>
      </StyledManufacturingTicket>
      {declinedNoteModal.isOpen && (
        <DeclinedNote close={declinedNoteModal.close} state={declinedNoteModal.state} />
      )}
    </>
  );
};

const StyledManufacturingTicket = styled.div<{
  isActive: boolean;
  isCritical?: boolean;
  isDragged: boolean;
  isRecentlyMoved: boolean;
  isComplaint: boolean;
}>`
  background: var(--neutralWhite100);
  border: 1px solid var(--neutralWhite100);
  border-radius: 4px;
  box-shadow: 0px 2px 4px -2px #0000001F;
  cursor: pointer;
  margin-bottom: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50px;
  padding: 4px 8px;
  &:hover {
    background: var(--grey25);
    border: 1px solid var(--neutralBlack20);
  }
  ${props =>
    props.isComplaint &&
    css({
      backgroundColor: "var(--magenta12)",
    })}
  ${props =>
    props.isCritical &&
    css({
      border: "1px solid var(--danger500)",
    })}
  ${props =>
    props.isActive &&
    css({
      background: "var(--deepPurple75)",
      border: "1px solid var(--neutralBlack20)",
    })}
  ${props =>
    props.isDragged &&
    css({
      border: "1px solid var(--deepPurple400)",
      boxShadow: "0px 32px 32px -12px #0000001F",
    })}
  ${props =>
    props.isRecentlyMoved &&
    css({
      background: "var(--blue50)",
      border: props.isCritical ? "1px solid var(--danger500)" : "1px solid var(--blue50)",
      ":hover": {
        background: "var(--blue50)",
      },
    })}
`;

export const TicketIcon = styled.div<{
  cancelledElements?: number;
  declinedElements?: number;
  priority?: ManufacturingPriority;
}>`
  border-radius: 20px;
  border: 1px solid;
  padding: 1px;
  ${props =>
    props.priority &&
    css({
      borderColor:
        colorPalette[
          manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[props.priority].light
            .color
        ],
    })}
  ${props =>
    Boolean(props.cancelledElements) &&
    css({
      borderColor: "var(--deepOrange200)",
    })}
  ${props =>
    Boolean(props.declinedElements) &&
    css({
      borderColor: "var(--yellow400)",
    })}
  }
`;

export const TicketMarker = styled.div<{
  priority: ManufacturingPriority;
}>`
  border-radius: 4px;
  height: 20px;
  width: 4px;
  ${props =>
    props.priority &&
    css({
      background:
        colorPalette[
          manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[props.priority].light
            .color
        ],
    })}
`;

const ignoreClickOutsideClassName = "manufacturing-ticket-assign-employee-ignore-class-name";
