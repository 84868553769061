import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { IconRendererIcon } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";

export const SelectableOption = ({
  checked,
  customIcon,
  isLoading,
  label,
  showIcon = true,
  onClick,
}: {
  checked: boolean;
  customIcon?: IconRendererIcon;
  isLoading: boolean;
  label: string;
  showIcon?: boolean;
  onClick: () => void;
}) => {
  return (
    <div className={isLoading ? "cursor-not-allowed" : "cursor-pointer"} onClick={onClick}>
      <Tag
        label={label}
        startIcon={
          !showIcon
            ? undefined
            : customIcon
            ? customIcon
            : checked
            ? MdiRadioButtonChecked
            : MdiRadioButtonUnchecked
        }
        type={checked ? "filled" : "outlined"}
        variant={checked ? "deepPurple50" : "quaternary"}
      />
    </div>
  );
};
